import { useQuery } from '@tanstack/react-query';
import { apiProvider } from '../../shared/api/apiProvider';
import { Helmet } from 'react-helmet';
import { ContentWrapper, IconLink, LoaderSmall, NavSlim } from '../../components';
import React, { useRef, useState } from 'react';
import { useContainerHeight } from '../../shared/utils/useContainerHeight';
import { Column } from 'primereact/column';
import { rooFmt } from '../../shared/utils';
import { DataTable as PrimeDataTable } from 'primereact/datatable';
import { rooEnum, useAppStore } from '../../shared/store';
import { CommissionInvoiceEntry, CommissionIssueEntry } from '@roo/api';
import { ColumnGroup } from 'primereact/columngroup';
import { Row } from 'primereact/row';
import { sumBy } from 'lodash';
import { Routes } from '../../shared/routing';
import { Stack, Typography } from '@mui/material';
import { Badge } from 'react-bootstrap';

export const CommissionsReportPage = () => {
  const { isLoading, data } = useQuery(['commissions', 'getReport'], () =>
    apiProvider.commissionsClient.getCommissionReport()
  );

  const hideReports = useAppStore((x) => x.hideReports);

  return (
    <>
      <Helmet>
        <title>Commissions - Walkthroo</title>
      </Helmet>
      <NavSlim breadcrumbs={[{ text: 'Commissions' }]} />
      <ContentWrapper className="container-property-list no-transparent">
        {isLoading && (
          <div className={'text-center p-4'}>
            <LoaderSmall />
          </div>
        )}
        {!isLoading && !hideReports && <CommissionsTable data={data} />}
      </ContentWrapper>
    </>
  );
};

const CommissionsTable = ({ data }: { data: CommissionIssueEntry[] }) => {
  const containerRef = useRef<HTMLDivElement>();
  const height = useContainerHeight({ itemRef: containerRef, defaultHeight: 500 });
  const [filteredData, setFilteredData] = useState(data);
  const [expandedRows, setExpandedRows] = useState<any>({});

  const [filters] = useState({
    companyName: { operator: 'AND', constraints: [{ value: '', matchMode: 'contains' }] }
  });

  const footerGroup = (
    <ColumnGroup>
      <Row>
        <Column footer="Totals:" footerStyle={{ fontWeight: 'bold' }} />
        <Column
          className={'column-money'}
          footer={() => {
            const total = sumBy(filteredData, (x) => x.incomingFinalTotal ?? 0);
            return rooFmt.money(total);
          }}
        />
        <Column
          className={'column-money'}
          footer={() => {
            const total = sumBy(filteredData, (x) => x.profit ?? 0);
            return rooFmt.money(total);
          }}
        />
        <Column
          className={'column-money'}
          footer={() => {
            const total = sumBy(filteredData, (x) => x.operatorShare ?? 0);
            return rooFmt.money(total);
          }}
        />
        <Column
          className={'column-money'}
          footer={() => {
            const total = sumBy(filteredData, (x) => x.supportShare ?? 0);
            return rooFmt.money(total);
          }}
        />
        <Column
          className={'column-money'}
          footer={() => {
            const total = sumBy(filteredData, (x) => x.rooShare ?? 0);
            return rooFmt.money(total);
          }}
        />
      </Row>
    </ColumnGroup>
  );

  return (
    <div ref={containerRef}>
      <PrimeDataTable
        onValueChange={(data: any[]) => setFilteredData(data)}
        filters={filters as any}
        scrollHeight={`${height}px`}
        scrollable
        value={data}
        rowExpansionTemplate={rowExpansionTemplate}
        onRowToggle={(e) => {
          setExpandedRows(e.data);
        }}
        expandedRows={expandedRows}
        sortOrder={-1}
        sortField={'total'}
        dataKey="issueId"
        footerColumnGroup={footerGroup}
      >
        <Column style={{ maxWidth: '80px' }} expander />
        <Column
          field={'property.address.address1'}
          sortable
          header={'Address'}
          body={(row: CommissionIssueEntry) => row.property?.displayAddress}
        />
        <Column
          field={'property.referenceDate'}
          sortable
          header={'Date'}
          body={(row: CommissionIssueEntry) => rooFmt.instantDate(row.referenceDate)}
        />
        <Column
          field={'friendlyId'}
          sortable
          header={'Issue #'}
          body={(row: CommissionIssueEntry) => (
            <IconLink
              target={'_blank'}
              style={{ whiteSpace: 'nowrap' }}
              to={{ pathname: Routes.IssueView, params: { issueId: row.issueId, workorderId: row.workorderId } }}
              text={row.friendlyId}
            />
          )}
        />
        <Column
          field={'row.accountManager.fullName'}
          sortable
          header={'Account Manager'}
          body={(row: CommissionIssueEntry) => row.accountManager?.fullName}
        />
        <Column
          field={'incomingFinalTotal'}
          sortable
          header={'Amount'}
          dataType={'numeric'}
          className={'column-money'}
          bodyClassName={'column-money'}
          body={(row: CommissionIssueEntry) => rooFmt.money(row.incomingFinalTotal)}
        />
        <Column
          field={'profit'}
          sortable
          header={'Profit'}
          dataType={'numeric'}
          className={'column-money'}
          bodyClassName={'column-money'}
          body={(row: CommissionIssueEntry) => rooFmt.money(row.profit)}
        />
        <Column
          field={'operatorShare'}
          sortable
          header={'Operator $'}
          dataType={'numeric'}
          className={'column-money'}
          bodyClassName={'column-money'}
          body={(row: CommissionIssueEntry) => rooFmt.money(row.operatorShare)}
        />
        <Column
          field={'supportShare'}
          sortable
          header={'Support $'}
          dataType={'numeric'}
          className={'column-money'}
          bodyClassName={'column-money'}
          body={(row: CommissionIssueEntry) => rooFmt.money(row.supportShare)}
        />
        <Column
          field={'rooShare'}
          sortable
          header={'Roo $'}
          dataType={'numeric'}
          className={'column-money'}
          bodyClassName={'column-money'}
          body={(row: CommissionIssueEntry) => rooFmt.money(row.rooShare)}
        />
      </PrimeDataTable>
    </div>
  );
};

const rowExpansionTemplate = (row: CommissionIssueEntry) => {
  return (
    <Stack spacing={2} px={4} py={2}>
      <Stack spacing={1}>
        <Typography variant={'h6'}>Outgoing</Typography>
        {row.outgoingInvoices.length === 0 && <Typography>No outgoing invoices</Typography>}
        {row.outgoingInvoices.length > 0 && <InvoiceTable data={row.outgoingInvoices} />}
      </Stack>

      <Stack spacing={1}>
        <Typography variant={'h6'}>Incoming</Typography>
        {row.incomingInvoices.length === 0 && <Typography>No incoming invoices</Typography>}
        {row.incomingInvoices.length > 0 && <InvoiceTable data={row.incomingInvoices} />}
      </Stack>
    </Stack>
  );
};

const InvoiceTable = ({ data }: { data: CommissionInvoiceEntry[] }) => {
  return (
    <PrimeDataTable value={data} sortOrder={-1} sortField={'total'} dataKey="invoiceId">
      <Column
        field={'friendlyIssueId'}
        sortable
        header={'Issue #'}
        body={(row: CommissionInvoiceEntry) => (
          <IconLink
            target={'_blank'}
            style={{ whiteSpace: 'nowrap' }}
            to={{ pathname: Routes.IssueView, params: { issueId: row.issueId, workorderId: row.workorderId } }}
            text={row.friendlyIssueId}
          />
        )}
      />
      <Column
        field={'friendlyId'}
        sortable
        header={'Invoice #'}
        body={(row: CommissionInvoiceEntry) => row.friendlyId}
      />

      <Column
        field={'date'}
        sortable
        header={'Date'}
        body={(row: CommissionInvoiceEntry) => rooFmt.instantDate(row.date)}
      />

      <Column
        field={'status'}
        sortable
        header={'Status'}
        body={(row: CommissionInvoiceEntry) => (
          <Badge bg={rooEnum.invoiceStatus.color(row.status)}>{rooEnum.invoiceStatus.display(row.status)}</Badge>
        )}
      />

      <Column
        field={'finalTotal'}
        sortable
        header={'Final Total'}
        dataType={'numeric'}
        className={'column-money'}
        bodyClassName={'column-money'}
        body={(row: CommissionInvoiceEntry) => rooFmt.money(row.finalTotal)}
      />
    </PrimeDataTable>
  );
};
